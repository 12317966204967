
import { NavBar, Icon, Toast, DropdownMenu, DropdownItem, Search, Swipe, SwipeItem, PullRefresh, List, Image, } from 'vant'

export default {
  components:{
    'van-icon': Icon,
    'van-search': Search,
    'van-swipe': Swipe,
    'van-list': List,
    'van-image': Image,
    'van-nav-bar': NavBar,
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-swipe-item': SwipeItem,
    'van-pull-refresh': PullRefresh,
  },
  data () {
    return {

      option1: [
        { text: '按类型', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      apis:{
        scenics:'/seller/list',//获取景点列表
        citys:'/baseArea/getAllArea',//获取区县及热门城市
        banners:'/banner/queryBanner',//获取banner列表
        queryCommodityTypeName: '/seller/querySellerTypeName', //查询title
      },
      titleName:'',
      banners:[],//Banner列表
      citys:[],//城市列表
      scenics:[],//景点列表
      params:{//门票搜索条件
        sellerTypeCode: 'yyss', //酒店固定类型编码        name:'',//关键字，目的地，名称
        pageIndex:1,//当前页码
        pageSize:5,//每页显示记录数
        longitude:103.501404,//游客经度
        latitude:29.594466,//游客纬度
      },
      refreshing:false,//是否刷新
      loading: false,//是否加载中
      finished: false,//数据是否已经加载完成
      iconNum:0,
      dropdownValue:{//下拉菜单的值
        tag:'none',
        priceSort: 'none',
        starSort: 'none'
      },
      tags: [//商家标签
        { text: '按类型', value: 'none' },//此值和后台查询景点门票的值保持一致
        { text: '新款商品', value: '新款商品' },
        { text: '活动商品', value: '活动商品' }
      ],
      priceSort: [
        { text: '按价格', value: 'none' },
        { text: '价格升序', value: 'showPrice-asc' },
        { text: '价格降序', value: 'showPrice-desc' },
      ],
      starSort: [
        { text: '按评分', value: 'none' },
        { text: '评分升序', value: 'star-asc' },
        { text: '评分降序', value: 'star-desc' },
      ],
      isShowLoginPop:false
    }
  },
  created(){

    if(this.$route.query.sellerTypeCode){
      localStorage.setItem('sellerTypeCodeD',this.$route.query.sellerTypeCode);
      this.params.sellerTypeCode = this.$route.query.sellerTypeCode
    }
    if(localStorage.getItem('sellerTypeCodeD')){
      this.params.sellerTypeCode = localStorage.getItem('sellerTypeCodeD')
    }
  },
  mounted() {
    const me = this;
    localStorage.setItem('page','deduce');
    me.loadCitys();
    me.loadBanners();
    me.getName();
    // me.loadScenics();
  },
  methods:{
    getName(){
      let me = this;
      me.$get({
        url: me.apis.queryCommodityTypeName,
        params: {
          code: this.params.sellerTypeCode
        }
      }).then(rsp => {
        this.titleName = rsp.name;
      });
    },
    revert(){
      this.$router.go(-1)
    },
    onChange (index) {
      this.iconNum = index
    },
    //跳转到详情界面
    toDetail(key,priceCalendarCount) {
      if(priceCalendarCount>0){
        // 根据token判断是否跳转登录
        if(this.$global.token){
          this.$router.push({
            path:'/tiketDetails/' + key,
            query:{
              priceCalendarCount:priceCalendarCount
            }
          });
        }else{
          this.isShowLoginPop = true;
        }
      }else{
        this.$router.push('/deduceDetails/' + key)
      }
    },
    tiketRouter (key) {
      this.$router.push('/deduceDetails/' + key)
    },
    loadCitys(){//获取热门城市
      const me = this;
      me.citys = [];
      me.$get({
        url:me.apis.citys,
        params: {
          adCode:'qy-ls'
        }
      }).then(rsp => {
        if(rsp && rsp.length > 0){
          var city = null;
          me.cityScr(city, rsp);
        }

      });
    },
    cityScr(city,rsp){
      const me = this;
      me.citys.push({
        text:'全部',
        value:''
      });
      me.params.areaId = '';
      for(let i = 0; i < rsp.length; i++){
        me.citys.push({
          text: rsp[i].name,
          value: rsp[i].areaId
        });
      }
      me.loadScenics()
    },
    loadScenics(){//获取全部景点列表
      const me = this;
      me.loading = true;
      me.$get({
        url:me.apis.scenics,
        params: me.params
      }).then(rsp => {
        me.loading = false;
        if(me.refreshing){
          me.scenics = [];
          me.refreshing = false;
        }

        var list = rsp.data;
        if(list && list.length > 0){
          me.scenics = me.scenics.concat(list);
        }
        if(!list || list.length < me.params.pageSize){
          me.finished = true;
        }
      });
    },
    tagChange(v){//筛选类型改变时执行
      const me = this;
      me.refreshing = true;
      if(v != 'none'){
        me.params.tags = v;
      }else{
        me.params.tags = null;
      }
      me.params.pageIndex = 1;
      me.loadScenics();
    },
    sortChange(v){//排序方式改变时执行
      const me = this;
      if(v == 'showPrice-asc' || v == 'showPrice-desc' || v == 'star-asc' || v == 'star-desc'){
        let sorts = v.split('-');
        me.params.sortName = sorts[0];
        me.params.sortOrder = sorts[1];
        if(sorts[0] == 'showPrice'){
          me.params.starSort = 'none';
        }else{
          me.dropdownValue.priceSort = 'none';
        }
      }else{
        me.params.sortName = null;
        me.params.sortOrder = null;
        me.dropdownValue.priceSort = 'none';
        me.params.starSort = 'none';

      }
      me.params.pageIndex = 1;
      me.loadScenics();
    },
    search(){//查询
      this.refreshing = true;
      this.onRefresh();
    },
    onRefresh () {//下拉刷新
      const me = this;
      me.finished = false;
      me.params.pageIndex = 1;
      me.loadScenics();
    },
    onLoad(){//上拉加载
      const me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.loadScenics();
    },
    loadBanners(){//获取banner列表
      const me = this;
      me.$get({
        url:me.apis.banners,
        params: {
          position: 8
        }
      }).then(rsp => {
        me.banners = rsp;
      });
    }
  }
}
